import { Inject, Injectable, LOCALE_ID, PLATFORM_ID, } from '@angular/core';
import { BaseWalletService } from '../http/base-wallet.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { GAME_HISTORY } from '../http/base-http.service';
import { GameHistoryResponse } from './game.models';
import { BehaviorSubject, Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, flatMap, map, of, skipWhile, switchMap, take, tap } from 'rxjs';
import { HOST_NAME, LICENCE } from 'src/app/global.tokens';
import { Apollo } from 'apollo-angular';
import { countryFromLocale } from 'src/app/utils/country-from-locale';
import { getSearchGameQuery, SEARCH_FILTERS_QUERY, SEARCH_GAME_QUERY } from 'src/app/apollo/search-games/search-games'
import { GameItem } from 'src/app/apollo/models/base-models';
import { ApolloQueryResult } from '@apollo/client';
import { FrontPageGamesService } from '../page/front-games.service';

// filter-config.ts

interface Asset {
  __typename: string;
  url: string;
}

interface Color {
  __typename: string;
  css: string;
}

interface ProviderExclusionTerritory {
  __typename: string;
  countryCode: string;
  state: string | null;
}

interface GameProvider {
  __typename: string;
  name: string;
  providerExclusionTerritories: ProviderExclusionTerritory[];
}

interface Game {
  __typename: string;
  name: string;
  id: string;
  gameid: string;
  systemName: string;
  hideGame: boolean | null;
  tag: any[];
  initialLoadColor: Color;
  thumbnail: Asset;
  thumbnailDesktopSquare: Asset;
  thumbnailDesktopRectangular: Asset;
  gameProvider: GameProvider;
  animation: any[];
}

export interface GameCategory {
  __typename: string;
  name: string;
  id: string;
  displayName: string;
  url: string;
  numberGamesFrontPageMobile: number;
  numberGameRows: number;
  icon: Asset;
  thumbnail: Asset;
  game: Game[];
}

/* ----------------------------------------------------------- */

export interface SearchConfig {
  // searchStrings: SearchConfigStringItem[],
  searchFilters: SearchConfigFilterItem[],
}

export interface SearchConfigFilterItem {
  name: string
  storageKey: string,
  displayName: string,
  displayHeader?: string,
  data: SearchDataItem[],
  queryVar: string,
  activeFilters: number,
}

export interface SearchDataItem {
  name: string,
  id?: string,
  displayName: string,
  //thumbnailMobile?: { url: string },
  selected: boolean,
  //icon?: string,
}

export const SearchConfig: SearchConfig = {
  searchFilters: [
    {
      name: 'categories',
      storageKey: 'categoriesKey',
      displayName: $localize`:@@search-game-categories-displayName:Categories`,
      displayHeader: $localize`:@@search-game-categories-displayHeader:Game Categories`,
      data: [],
      queryVar: 'categoriesIn',
      activeFilters: 0
    },
    {
      name: 'operators',
      storageKey: 'operatorsKey',
      displayName: $localize`:@@search-game-operators-displayName:Providers`,
      displayHeader: $localize`:@@search-game-operators-displayHeader:Game Providers`,
      data: [],
      queryVar: 'operatorsIn',
      activeFilters: 0
    },
  ]
}

export const swiperConfig = {
  name: 'categories',
  categories: [
    {
      name: 'top',
      displayName: 'Top Games',
      icon: 'assets/pages/frontpage/icon-top-games.png'
    },
    {
      name: 'most played',
      displayName: 'Most Played',
      icon: 'assets/pages/frontpage/icon-most-played.png'
    },
    {
      name: 'live-casino',
      displayName: 'Live Casino',
      icon: 'assets/pages/frontpage/icon-live-casino.png'
    },
    {
      name: 'arcade',
      displayName: 'Top Arcade',
      icon: 'assets/pages/frontpage/icon-Arcade.png'
    },
    {
      name: 'big-wins',
      displayName: 'Big Wins',
      icon: 'assets/pages/frontpage/icon-most-played.png'
    },
    {
      name: 'slots',
      displayName: 'Top Slots',
      icon: 'assets/pages/frontpage/icon-Arcade.png'
    },
  ]
}

@Injectable({
  providedIn: 'root'
})
export class SearchGameService {

  public searchKey: string = 'searchTermHistory';

  private searchFiltersSubject = new BehaviorSubject<SearchConfigFilterItem[]>([]);
  private lastSearchGames: GameItem[];

  constructor(
    private apollo: Apollo,
    private frontPageGamesService: FrontPageGamesService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public licence: string,
    @Inject(PLATFORM_ID) public platformId: string,
  ) {
    //localStorage.clear()
  }

  /* ------------------------------------------------------------------------------------------------------- */

  get searchFilters$() {
    return this.searchFiltersSubject.asObservable();
  }

  setSearchFilters(searchFilters: SearchConfigFilterItem[]) {
    searchFilters.forEach((filter) => {
      let selectedFilters = this.extractSelectedFilter(filter);
      this.saveFilterHistory(filter.storageKey, selectedFilters);
    })

    //console.log('setSearchFilters =>', searchFilters)

    this.searchFiltersSubject.next(searchFilters);
  }

  /* ------------------------------------------------------------------------------------------------------- */

  setSearchSwiper(categoryName: string): void {
    this.getFilters().pipe(
      map((filters) => this.setFilters(filters, SearchConfig)),
      map((searchConfig: SearchConfig) => {
        const searchCategories = searchConfig.searchFilters;
        const categories = searchConfig.searchFilters.find((item) => item.name === 'categories');
        if (categories && categories.data) {
          categories.data = categories.data.filter((item) => item.displayName && item.displayName.trim() !== '');
        }

        this.searchFiltersSubject.next(searchCategories);

        searchCategories.forEach(filter => {
          filter.data.forEach(item => {
            if (item.name === categoryName) {
              item.selected = true;
            }
          });
        });

        this.setSearchFilters(searchCategories);
      })
    ).subscribe();
  }

  setHistory(searchConfig: SearchConfig) {
    let isHistory = false;

    searchConfig.searchFilters.forEach((filterConfig) => {
      const savedData = this.getFilterHistory(filterConfig.storageKey);
      //console.log('for filter =>', filterConfig.name, 'loaded =>', savedData)

      if (savedData.length > 0) {
        isHistory = true
      }

      filterConfig.data = filterConfig.data.map((item) => ({
        ...item,
        selected: savedData.includes(item.name)
      }));

      filterConfig.activeFilters = filterConfig.data.reduce((count, item) => item.selected ? count + 1 : count, 0);
    });

    return { searchConfig, isHistory };
  }

  saveSearchHistory(searchString: string) {
    localStorage.setItem(this.searchKey, searchString);
  }

  getSearchHistory() {
    return localStorage.getItem(this.searchKey) || '';
  }

  getFilterHistory(storageKey: string): string[] {
    const savedData = localStorage.getItem(storageKey);
    return savedData ? JSON.parse(savedData) : [];
  }

  saveFilterHistory(storageKey: string, selectedFilters: string[]) {
    localStorage.setItem(storageKey, JSON.stringify(selectedFilters));
  }

  setFilters(filters: any, searchConfig: SearchConfig) {
    searchConfig.searchFilters.forEach((filterConfig) => {
      if (filters.data[filterConfig.name]) {
        filterConfig.data = filters.data[filterConfig.name].data;
        filterConfig.data = filterConfig.data.map((item) => ({ ...item, selected: false }))
      }
    });

    return searchConfig;
  }

  getFilters() {
    return this.apollo.watchQuery<any>({
      query: SEARCH_FILTERS_QUERY,
      variables: {
        locale: this.locale,
        countryName: [countryFromLocale(this.locale)]
      }
    }).valueChanges.pipe(
      map(({ data }) => {
        const { operators, categories } = data;

        const mergedOperator = {
          ...operators[0],
          data: operators.flatMap((operator) => operator.data)
        };

        const mergedCategoties = {
          ...categories[0],
          data: categories.flatMap((category) => category.data)
        };

        return {
          data: {
            operators: mergedOperator,
            categories: mergedCategoties
          }
        };
      })
    );
  }

  /* ------------------------------------------------------------------------------------------------------- */

  extractSelectedFilter(searchFilter: SearchConfigFilterItem) {
    return searchFilter.data.filter((categories) => categories.selected).map((categories) => {
      return categories.name
    })
  }

  /* ------------------------------------------------------------------------------------------------------- */

  fullSearch(searchString: string, searchFilters: SearchConfigFilterItem[]): Observable<GameItem[]> {
    let filterVariables = {};
    let isFullFilterMap = {};

    //console.log('searchFilters =>', searchFilters);

    searchFilters.forEach((filter) => {
      let selectedFilters = this.extractSelectedFilter(filter);
      this.saveFilterHistory(filter.storageKey, selectedFilters);
  
      if (selectedFilters.length === 0) {
        selectedFilters = filter.data.map((res) => res.name);
      }
  
      filterVariables[filter.queryVar] = selectedFilters;
      isFullFilterMap[filter.queryVar] = filter.data.length === selectedFilters.length;
    });

    const variables = {
      locale: this.locale,
      countryName: [countryFromLocale(this.locale)],
      licenseName: this.licence,
      nameIn: searchString,
      ...filterVariables,
    };
  
    const queryFilterKeys = Object.keys(filterVariables);

    return this.apollo.watchQuery<any>({
      query: getSearchGameQuery(queryFilterKeys, isFullFilterMap),
      variables: variables
    }).valueChanges.pipe(
      //tap((res) => console.log(`* fullSearch`, res)),
      map((res) => {
        const gameCategories = res.data.gameCategories || [];
        const maxGames = Math.max(...gameCategories.map(category => category.game.length));
      
        const allGames: GameItem[] = [];
      
        for (let i = 0; i < maxGames; i++) {
          gameCategories.forEach(category => {
            if (category.game[i]) {
              allGames.push(category.game[i]);
            }
          });
        }
      
        return allGames;
      })
      // map((games) => {
      //   return games.filter((game) => variables.licenseName === game.licence || game.licence === 'all');
      // })
    );

  }

  initialSearch(searchConfig: SearchConfigFilterItem[]) {
    return this.frontPageGamesService.fetchData().pipe(
      take(1),
      map((res) => {
        const selectedFilters = searchConfig.flatMap((filter) => {
          const selected = this.extractSelectedFilter(filter);
          this.saveFilterHistory(filter.storageKey, selected);

          if (selected.length === 0) {
            return filter.data.map((item) => item.name); // select all if none are selected
          }

          return selected;
        });

        let filteredCategories = res.pagesCategories[0]?.categories?.filter((category) => {
          return selectedFilters.some((filter) => filter.includes(category.name));
        });

        return filteredCategories;
      })
    );
  }

  /* ------------------------------------------------------------------------------------------------------- */

  isGameItemArray(resp: any[]): resp is GameItem[] {
    return resp.every(item => 'gameProvider' in item);
  }

  isGameCategoryArray(resp: any[]): resp is GameCategory[] {
    return resp.every(item => 'game' in item && Array.isArray(item.game));
  }

}
